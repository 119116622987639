import React from "react";
import styled from "styled-components";

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 82vh;
  background-color: #fff;
  color: #220033;
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
  }
`;

const Maintenance = () => {
  return (
    <MaintenanceContainer>
      <h1>Aguarde!</h1>
      <p>Nossa página está em manutenção.</p>
      <p>Estamos trabalhando para trazer novidades em breve.</p>
    </MaintenanceContainer>
  );
};

export default Maintenance;
