import React from "react";
import {
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaHome,
  FaUsers,
  FaProjectDiagram,
  FaImages,
  FaHandHoldingHeart,
  FaFacebook,
} from "react-icons/fa";
import styled from "styled-components";
import { Logo } from "../assets/images";

const FooterContainer = styled.footer`
  background-color: #220033;
  padding: 20px 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .border {
    width: 100%;
    border-top: 2px dotted #fff !important;
    margin-bottom: 26px;
    border-bottom: none !important;
  }

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1110px;
  gap: 20px;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  justify-content: center;

  img {
    max-width: 120px;
    max-height: 80px;
  }
`;

const Sitemap = styled.div`
  h4 {
    margin-bottom: 10px;
  }
  a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  a:hover {
    color: #fd7e14;
  }
  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Contacts = styled.div`
  h4 {
    margin-bottom: 10px;
  }
  a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  a:hover {
    color: #fd7e14;
  }
  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Copyright = styled.div`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
`;

function Footer() {
  return (
    <FooterContainer>
      <div className="border" />
      <FooterContent>
        <FooterLogo>
          <img src={Logo} alt="Logo" />
        </FooterLogo>
        <Sitemap>
          <h4>Mapa do Site</h4>
          <a href="/">
            <FaHome /> Home
          </a>
          <a href="/quem-somos">
            <FaUsers /> Quem Somos
          </a>
          <a href="/projetos">
            <FaProjectDiagram /> Nossos Projetos
          </a>
          <a href="/galeria">
            <FaImages /> Galeria de Fotos
          </a>
          <a href="/contato">
            <FaEnvelope /> Contato
          </a>
          <a href="#donation">
            <FaHandHoldingHeart /> Doe Agora
          </a>
        </Sitemap>
        <Contacts>
          <h4>Contato</h4>
          <a href="mailto:supervisao@institutoceciliameireiles.org.br">
            <FaEnvelope /> supervisao@institutoceciliameireiles.org.br
          </a>
          <a href="tel:+5511999999999">
            <FaPhone /> +55 (11) 99999-9999
          </a>
          <a href="https://facebook.com">
            <FaFacebook /> Facebook
          </a>
          <a href="https://instagram.com/instceciliameireles/">
            <FaInstagram /> Instagram
          </a>
        </Contacts>
      </FooterContent>
      <Copyright>
        Copyright&copy; {new Date().getFullYear()} Instituto Cecília Meireiles.
        Todos os direitos reservados. | Desenvolvido por{" "}
        <a
          href="https://www.linkedin.com/in/beatriciframos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Beatrici Ramos
        </a>
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;
