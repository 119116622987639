import React from "react";
import { BoxContainer, Container } from "./styles";
import styled from "styled-components";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 20px;
  background-color: #f8f8f8;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 45%;
    margin-bottom: 0;
  }
`;

const ContactInfoContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 45%;
  }
`;

const FormTitle = styled.h2`
  color: #220033;
  margin-bottom: 20px;
`;

const ContactTitle = styled.h2`
  color: #220033;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 15px;
  color: #555;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fd7e14;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f57c00;
  }
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const ContactIcon = styled.div`
  font-size: 24px;
  color: #fd7e14;
  margin-right: 10px;
`;

const ContactText = styled.p`
  font-size: 16px;
  color: #555;
`;

const WhatsAppLink = styled.a`
  color: #fd7e14;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

function Contato() {
  return (
    <Container>
      <BoxContainer>
        <ContactContainer>
          <FormContainer>
            <FormTitle>Contato</FormTitle>
            <Form>
              <FormGroup>
                <Label htmlFor="name">Nome</Label>
                <Input type="text" id="name" name="name" required />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">E-mail</Label>
                <Input type="email" id="email" name="email" required />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">Telefone</Label>
                <Input type="tel" id="phone" name="phone" />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="message">Mensagem</Label>
                <TextArea id="message" name="message" rows="4" required />
              </FormGroup>
              <SubmitButton
                type="submit"
                className="dtr-btn btn-grad-orange dtr-scroll-link"
              >
                Enviar
              </SubmitButton>
            </Form>
          </FormContainer>
          <ContactInfoContainer>
            <ContactTitle>Informações de Contato</ContactTitle>
            <ContactItem>
              <ContactIcon>
                <FaPhone />
              </ContactIcon>
              <ContactText>(11) 1234-5678</ContactText>
            </ContactItem>
            <ContactItem>
              <ContactIcon>
                <FaEnvelope />
              </ContactIcon>
              <ContactText>contato@institutocm.org.br</ContactText>
            </ContactItem>
            <ContactItem>
              <ContactIcon>
                <FaMapMarkerAlt />
              </ContactIcon>
              <ContactText>
                Rua Exemplo, 123 - Parque São Rafael, São Paulo - SP
              </ContactText>
            </ContactItem>
            <ContactItem>
              <ContactIcon>
                <FaWhatsapp />
              </ContactIcon>
              <ContactText>
                <WhatsAppLink
                  href="https://wa.me/5511999999999"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Enviar mensagem pelo WhatsApp
                </WhatsAppLink>
              </ContactText>
            </ContactItem>
            <ContactItem>
              <ContactIcon>
                <FaInstagram />
              </ContactIcon>
              <ContactText>
                <a
                  href="https://www.instagram.com/instceciliameireles/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#fd7e14", textDecoration: "none" }}
                >
                  Siga-nos no Instagram
                </a>
              </ContactText>
            </ContactItem>
          </ContactInfoContainer>
        </ContactContainer>
      </BoxContainer>
    </Container>
  );
}

export default Contato;
