import React from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";
import { BoxContainer, Container, Heading, HeroSection } from "./styles";
import Img from "../assets/images/imgCAI-4.png";
import Img2 from "../assets/images/imgCAI-2.png";
import Img3 from "../assets/images/imgCAI-3.jpg";
import {
  CI1,
  CI2,
  CI3,
  CI4,
  CII1,
  CII2,
  CII3,
  CII4,
  CII5,
  CA1,
  CA2,
  CA3,
  CA4,
  CP1,
  CP2,
  CP3,
  CL1,
  CL2,
  CL3,
  CL4,
  CIO1,
  CIO2,
  CIO3,
} from "../assets/images";
import MiniMenu from "../components/mini-menu";

const ProjectSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.grayBackground ? "#f0f0f0" : "#fff")};
  padding: 60px 0;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: ${(props) =>
      props.grayBackground ? "row-reverse" : "row"};
  }
`;

const LeftTextContainer = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`;

const RightTextContainer = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: right;
  }
`;

const CarouselContainer = styled.div`
  flex: 1;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;

const ProjectTitle = styled.h2`
  margin-bottom: 0;
`;

const ProjectSubtitle = styled.p`
  color: #28385e;
  font-size: 1rem;
  font-weight: 500;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const ProjectCarousel = styled(Carousel)`
  .carousel-item {
    height: 300px;
    @media (min-width: 768px) {
      height: 500px;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;

const projects = [
  {
    idDiv: "#CI1",
    icon: 1,
    title: "CEI Crescendo com Atitude I",
    area: "Educação",
    description: `O CEI iniciou suas atividades em 2019 e, apesar da pandemia, continuou a apoiar as famílias mesmo durante a suspensão das atividades presenciais. O atendimento ocorre das 07:00 às 17:00, com foco em promover inclusão, equidade e autonomia por meio de brincadeiras e interações, respeitando as necessidades e especificidades de cada criança.
      As vivências proporcionadas aos bebês e crianças seguem o Currículo da Cidade de São Paulo e outros referenciais da educação infantil, enriquecendo as trocas entre os pequenos. Além disso, formações contínuas são realizadas para fortalecer a equipe pedagógica e os vínculos com as famílias, abordando temas relevantes ao contexto da unidade, conforme orientações recebidas da DIPED.`,
    images: [Img, Img2, Img3, CI1, CI2, CI3, CI4],
  },
  {
    idDiv: "#CI2",
    icon: 2,
    title: "CEI Crescendo com Atitude II",
    area: "Educação",
    description: `As atividades do CEI começaram em 01/06/2022, conforme a autorização DOC SEI nº 064244439, com 80% das crianças matriculadas e 20% das vagas ainda disponíveis. O planejamento das experiências e estratégias foi alinhado ao Projeto Político Pedagógico da Unidade e ao Currículo da Cidade, garantindo as aprendizagens das crianças.
      O atendimento é presencial, das 07h às 17h, com vivências elaboradas pelas professoras, adaptadas à faixa etária das crianças, promovendo interação, autonomia, e exploração lúdica. A comunicação com os pais é contínua, seja por telefone, presencialmente no CEI, ou via plataformas como WhatsApp e Facebook.
      A unidade também dispõe de um painel informativo na entrada. As ações formativas para professores ocorrerão durante todo o período, focando na formação "Infâncias em Foco" e na implementação do Currículo da Cidade Educação Infantil, além de outros documentos curriculares da Rede Municipal de São Paulo.`,
    images: [CII1, CII2, CII3, CII4, CII5],
  },
  {
    idDiv: "#CA",
    icon: 3,
    title: "Projeto Casa Abrigo",
    area: "Assistência Social e Direitos Humanos",
    description: `As Casas Abrigo Dandara e Marielle, em parceria com o Consórcio Intermunicipal do ABC, atendem 20 mulheres em situação de violência, acompanhadas de seus filhos de até 17 anos. Cada casa conta com uma equipe composta por uma coordenadora, duas assistentes sociais, uma psicóloga, 12 educadoras socioeducativas e quatro membros da equipe operacional.
      As atividades desenvolvidas visam atender as necessidades biopsicossociais das mulheres e crianças abrigadas. Atendimentos individuais são realizados semanalmente para entender as demandas, elaborar projetos de vida e oferecer acolhimento psicológico. Além disso, a equipe promove rodas de conversa sobre as diferentes formas de violência, com o objetivo de prevenir a sua repetição dentro do ambiente das casas.
      As acolhidas e seus filhos também recebem encaminhamentos para serviços externos, como DDM, UBS, UPA, CAPS e outros, para garantir cuidados médicos e jurídicos. As educadoras são responsáveis por auxiliar nas tarefas cotidianas, como higiene e alimentação, além de acompanhar as acolhidas em compromissos externos. Elas também organizam oficinas lúdicas e socioeducativas, buscando fortalecer os vínculos familiares e sociais.`,
    images: [CA1, CA2, CA3, CA4],
  },
  {
    idDiv: "#CP",
    icon: 4,
    title: "Projeto Casa Passagem",
    area: "Assistência Social e Direitos Humanos",
    description: `O objetivo principal da instituição é acolher mulheres entre 18 e 59 anos, acompanhadas ou não de seus filhos e/ou dependentes, que estejam em situação de violência com ameaça ou risco de morte. O acolhimento oferece suporte psicológico, assistência em saúde, e desenvolvimento de atividades socioeducativas que promovem a autonomia e emancipação das acolhidas e seus dependentes.
      O abrigamento, de 15 a 30 dias, inclui a escuta qualificada das demandas prioritárias e os encaminhamentos necessários para atender essas necessidades. As atividades socioeducativas, como o "Balão da Ansiedade" e "Costura e Artesanato", são essenciais para trabalhar a concentração, motricidade e expressão de sentimentos, contribuindo para a nova jornada das acolhidas.
      A equipe técnica e educadoras são capacitadas para apoiar as acolhidas, enquanto a coordenação supervisiona e garante que o trabalho seja realizado de forma harmoniosa e eficaz.`,
    images: [CP1, CP2, CP3],
  },
  {
    idDiv: "#CL",
    icon: 5,
    title: "Projeto Casa Lar",
    area: "Assistência Social e Direitos Humanos",
    description: `O serviço Casa Lar, seguindo as normativas vigentes, acolhe crianças e adolescentes em situação de ameaça ou vulnerabilidade, oferecendo moradia, alimentação, educação e saúde. Cada casa acolhe até 10 crianças/adolescentes, com o objetivo de garantir proteção integral e prepará-los para a inserção na sociedade e no mercado de trabalho
      O serviço busca reintegrar as crianças/adolescentes à família de origem ou colocá-los em famílias substitutas, promovendo a convivência comunitária e o desenvolvimento sociopsicossocial. Atualmente, existem quatro casas lares em Itapevi, com capacidade total para 40 crianças/adolescentes.
      A metodologia adotada incentiva o desenvolvimento de hábitos de autonomia e integração social, em um ambiente próximo ao familiar. A equipe técnica multidisciplinar acompanha as crianças/adolescentes, bem como as mães sociais responsáveis pelas rotinas diárias.
      A coordenação supervisiona a equipe, mantém a documentação atualizada e promove a participação das crianças na comunidade, incentivando atividades culturais e de lazer. A transição para o desacolhimento é gradual, com visitas e fins de semana com as famílias antes da reintegração completa.
      Em 2023, o serviço desacolheu 22 crianças/adolescentes, dos quais 13 retornaram às famílias de origem, 8 foram para famílias substitutas, e 1 atingiu a maioridade. O acompanhamento continua mesmo após o desacolhimento, oferecendo suporte conforme necessário.`,
    images: [CL4, CL2, CL3, CL1],
  },
  {
    idDiv: "#CIO",
    icon: 6,
    title: "Capacitação em Projeto Casa Abrigo X Casa de Passagem CIOSTE",
    area: "Assistência Social e Direitos Humanos",
    description: `Durante o mês de junho e julho o Instito Cecilia Meireiles junto com a cioeste realizamos uma
      capacitação em rede juntamente com a a casa abrigo Cioeste com o objetivo principal para
      melhor conhecimento e entendimento do fluxo de acolhimento, tendo como prioridade um
      acolhimento de qualidade, otimizar o tempo de espera da mulher, fortalecer e ampliar os recursos
      disponíveis para mulheres que sofreram violência doméstica. Isso é alcançado através da
      formação de uma rede de apoio integrada, que oferece suporte emocional, jurídico e prático. A
      capacitação visa capacitar tanto as mulheres quanto os profissionais envolvidos, promovendo a
      autonomia, a segurança e o bem-estar das sobreviventes, enquanto cria um ambiente mais
      consciente e responsivo às necessidades específicas das vítimas de violência.`,
    images: [CIO1, CIO2, CIO3],
  },
];

const Projects = () => {
  return (
    <Container>
      <BoxContainer>
        <HeroSection>
          <Heading>Nossos Projetos</Heading>
          {projects.map((project, index) => (
            <ProjectSection
              key={index}
              id={project.idDiv}
              grayBackground={index % 2 !== 0}
            >
              {index % 2 === 0 ? (
                <>
                  <LeftTextContainer>
                    <ProjectTitle>{project.title}</ProjectTitle>
                    <ProjectSubtitle>{project.area}</ProjectSubtitle>
                    <ProjectDescription>
                      {project.description}
                    </ProjectDescription>
                  </LeftTextContainer>
                  <CarouselContainer>
                    <ProjectCarousel>
                      {project.images.map((image, i) => (
                        <Carousel.Item key={i}>
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Imagem do ${project.title}`}
                          />
                        </Carousel.Item>
                      ))}
                    </ProjectCarousel>
                  </CarouselContainer>
                </>
              ) : (
                <>
                  <RightTextContainer>
                    <ProjectTitle>{project.title}</ProjectTitle>
                    <ProjectSubtitle>{project.area}</ProjectSubtitle>
                    <ProjectDescription>
                      {project.description}
                    </ProjectDescription>
                  </RightTextContainer>
                  <CarouselContainer>
                    <ProjectCarousel>
                      {project.images.map((image, i) => (
                        <Carousel.Item key={i}>
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Imagem do ${project.title}`}
                          />
                        </Carousel.Item>
                      ))}
                    </ProjectCarousel>
                  </CarouselContainer>
                </>
              )}
            </ProjectSection>
          ))}
        </HeroSection>
        <MiniMenu projects={projects} />
      </BoxContainer>
    </Container>
  );
};

export default Projects;
